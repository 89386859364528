#Contenedor-Btn-Flotante-Reporte-Pagos-Promociones{
    background-color: #558CFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 72px;
    height: 72px;
    position: fixed;
    border-radius: 100px;
    cursor: pointer;
    padding: 5px;
    bottom: 60px;
    right: 30px;
}

#Contenedor-Btn-Flotante-Pdf-Promociones{
    background-color: #FCBE47;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 72px;
    height: 72px;
    position: fixed;
    border-radius: 100px;
    cursor: pointer;
    padding: 5px;
    bottom: 150px;
    right: 30px;
}







@-webkit-keyframes hvr-buzz-out {
    10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
    }
    20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
    }
    30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
    }
    40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
    }
    50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
    }
    60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
    }
    70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
    }
    80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
    }
    90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
    }
    100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
    }
}
@keyframes hvr-buzz-out {
    10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
    }
    20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
    }
    30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
    }
    40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
    }
    50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
    }
    60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
    }
    70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
    }
    80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
    }
    90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
    }
    100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
    }
}

.hvr-buzz-out {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
}

.hvr-buzz-out:hover, .hvr-buzz-out:focus, .hvr-buzz-out:active {
    -webkit-animation-name: hvr-buzz-out;
    animation-name: hvr-buzz-out;
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

#Contenedor-Promociones-Hoja{
    width: 100%;
    /* height: 100%; */
    /* background: red; */
    position: relative;
}



.Contenedor-CatIcono-Generar-Pdf-Promociones{
    background-color: transparent;
    border-radius: 50px;
    width: 60px;
}

.Txt-Titulo-Generador-Pdf-Promociones{
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
    color: #558CFF;

}

.Editar-Pdf-Generador-Promociones{
    width: 40px;
    height: 40px;
    border-radius: 50px;
    position: absolute;
    background-color: black;
    top: -10px;
    right: -5px;
    z-index: 10000;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: white;
}

.Icono-Flotante-Reporte-Pagos-Promociones-pdf{
    margin-right: 5px;
}

.Filtro-Cambio-Disenio-Promociones{
    width: 42px;
    height: 36px;
    border: 1px solid #1E1E1E;
    box-sizing: border-box;
    border-radius: 8px;
    margin-left: 20px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Img-Ojo-Cambio-Disenio-Promociones{
    position: absolute;
    width: 21px;
    height: 21px;
}