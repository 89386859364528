
.Contenedor-Perfil{
    padding-left: 40px;
    padding-top: 48px;
}

.Titulo-Perfil{
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Select-Anio-Perfil{
    width: 93px;
    height: 26px;
    background: #E5E5E5;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
}

.Texto-Select-Anio-Perfil{
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Select-Mes-Perfil{
    width: 93px;
    height: 26px;
    background: #E5E5E5;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    margin-top: 9px;
}

.Texto-Select-Mes-Perfil{
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Select-Canal-Perfil{
    width: 642px;
    height: 28px;
    background: #E5E5E5;
    margin-top: 9px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding-left: 15px;
}

.Texto-Select-Canal-Perfil{
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Btn-Guardar-Perfil{
    width: 66px;
    height: 24px;
    background: #3646C4;
    border: 1px solid #3646C3;
    box-sizing: border-box;
    border-radius: 14px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    justify-content: center;
    cursor: pointer;
    margin-top: 26px;
    position: absolute;
    right: 50px;
}

.Contenedor-Imagen-Perfil{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Contenedor-Informacion-Perfil {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -40px;
}

.Circulo-Editar-Perfil {
    width: 36px;
    height: 36px;
    background: #558CFF;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 9px;
}

.Contenido-Select-Anio{
    width: 93px;
    height: 84px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 8px 8px;
    cursor: pointer;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    top: 103px;
}

.Opciones-Select-Anio{
    width: 93px;
    height: 27px;
    background: #FFFFFF;
    text-align: center;
    padding-right: 30px;
    font-weight: 400;
    font-size: 12px;
    padding-top: 5px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Opciones-Select-Anio:hover{
    background: #E6F1FC;
}

.Contenido-Select-Anio-Oculto{
    display: none;
}

.Fila-Select-Anio-Mes-Perfil {
    display: flex;
    align-items: center;
}

.Anio-Mes-Seleccionado{
    /* width: 83px; */
    height: 26px;
    background: #E6F1FC;
    border-radius: 18px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;
    margin-top: 9px;
    margin-left: 19px;
}

.Texto-Anio-Mes-Seleccionado {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    margin-right: 23px;
}

.Contenido-Select-Mes{
    top: 137px;
    width: 93px;
    height: 84px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 8px 8px;
    cursor: pointer;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
}

.Contenido-Select-Mes-Oculto{
    display: none;
}

.Contenido-Select-Canal{

}

.Contenido-Select-Canal-Oculto{
    display: none;
}

.Opciones-Select-Canal-Perfil{
    width: 642px;
    height: 26px;
    border-bottom: 1px solid #E5E5E5;
    border-right: 1px solid #E5E5E5;
    border-left: 1px solid #E5E5E5;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    padding-left: 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Select-Canal-Perfil .ant-switch-checked{
    background-color: #a4a3a3;
}

.Select-Canal-Perfil .ant-switch .ant-switch-handle::before {
    background-color: #1BF785;
}

.Switch-Canal-Perfil{
    position: relative;
    left: 83%;
}

.Campo-Switch-Abrir-Cerrar{
    cursor: pointer;
    width: 93%;
}

.Opciones-Select-Canal-Perfil .ant-switch-checked{
    background-color: #FFFFFF;
}

.Opciones-Select-Canal-Perfil .ant-switch .ant-switch-handle::before {
    background-color: #1BF785;
}

.Opciones-Select-Canal-Perfil .ant-switch-small{
    border: 1px solid #A4A3A3;
    height: 18px !important;
    min-width: 30px !important;
}

.Switch-Estilos .ant-switch-small{
    border: 1px solid #A4A3A3;
    height: 19px !important;
    min-width: 32px !important;
}

.Opciones-Select-Descarga-Perfil{
    width: 529px;
    height: 26px;
    border-bottom: 1px solid #E5E5E5;
    border-right: 1px solid #E5E5E5;
    border-left: 1px solid #E5E5E5;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    padding-left: 33px;
    display: flex;
    align-items: center;
}

.Campo-Switch-Abrir-Cerrar-SubModulo-Descarga{
    position: relative;
    width: 91%;
    cursor: pointer;
}

.Switch-Submodulo .ant-switch{
    background-color: #FFFFFF
}
.Switch-Submodulo .ant-switch-checked{
    background-color: #FFFFFF
}

.Switch-Submodulo .ant-switch .ant-switch-handle::before {
    background-color: #FF3742;  
}

.Switch-Submodulo .ant-switch-checked .ant-switch-handle::before{
    background-color: #1BF785;
}

.Switch-Modulo .ant-switch{
    background-color: #A4A3A3
}

.Switch-Modulo .ant-switch-checked{
    background-color: #A4A3A3
}

.Switch-Modulo .ant-switch .ant-switch-handle::before {
    background-color: #FF3742;  
}

.Switch-Modulo .ant-switch-checked .ant-switch-handle::before{
    background-color: #1BF785;
}

.Switch-Estilos .ant-switch-small{
    border: 1px solid #A4A3A3;
    height: 19px !important;
    min-width: 32px !important;
}

.Input-Administrativo-Perfil input{
    text-align: center;
    width: 250px;
    height: 38px;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    margin-bottom: 5px;
}   
.Circulo-Editar-Check-Perfil{
    background: #3646C3;
    width: 36px;
    height: 36px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 9px;
}

.Submodulo-Descarga .ant-switch-small{
    border: 1px solid #A4A3A3;
    height: 19px !important;
    min-width: 32px !important;
}

.Input-Administrativo-Perfil .ant-form-item-control-input-content{
    height: 32px !important;
}

.Input-Administrativo-Perfil .ant-form-item{
    margin-bottom: 13px;
}

.Input-Administrativo-Perfil .ant-input:hover{
    border-color: #C4C4C4;
}

.Input-Administrativo-Perfil .ant-input:focus{
    border-color: #d9d9d9 !important;
}

.Switch-Administrativo-Perfil{
    display: flex;
    justify-content: center;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 8px;
    align-items: center;
    width: 250px;
    height: 38px;
    margin-bottom: 5px;
}

.Switch-Administrativo-Perfil span{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    margin-right: 15px;
}

.Input-Date-Administrativo-Perfil{
    text-align: center;
    width: 250px;
    height: 38px;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 16px;
}

.Input-Date-Administrativo-Perfil input{
    border: none;
    outline: none !important;
    border: 0 !important;
}

.Input-Date-Administrativo-Perfil .ant-form-item-control-input-content{
    height: 32px !important;
}

.Input-Date-Administrativo-Perfil .ant-form-item{
    margin-bottom: 0px;
}

.Input-Date-Administrativo-Perfil .ant-input:hover{
    border-color: #FFFFFF !important;
    box-shadow: none !important;
}

.Input-Date-Administrativo-Perfil .ant-input:focus{
    border-color: #FFFFFF !important;
    box-shadow: none !important;
}

.Contenedor-Btn-Editar{
    display: flex;
    align-items: center;
    justify-content: center;
}

.Contenedor-Fechas-EditarCrear-TiposUsuarios input {
    border: 1px solid #C4C4C4 !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
}

.Btn-Crear-Guardar-Perfil{
    width: 116px;
    height: 24px;
    background: #3646C4;
    border: 1px solid #3646C3;
    box-sizing: border-box;
    border-radius: 14px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    justify-content: center;
    margin-top: 26px;
    position: absolute;
    right: 50px;
    cursor: pointer;
}

.Caja-Modal-Tipo-Usuario .ant-modal-content{
    border-radius: 8px;
}

.Titulo-Modal-Tipo-Usuario{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    margin-bottom: 10px;
}

.Btn-Listo-Modal-Tipo-Usuario{
    width: 66px;
    height: 24px;
    background: #558CFF;
    border: 1px solid #558CFF;
    box-sizing: border-box;
    border-radius: 14px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    justify-content: center;
    margin-top: 17px;
}

.Contenedor-Modal-Tipo-Usuario{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}