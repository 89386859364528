.Contenedor-Tabla-Control{
    margin-top: 33px;
    /* margin-left: 96px; */
    width: 1148px;
    border-radius: 8px;
}

.Tabla-Adm-Control{
    width: 100%;
    text-align : center;
    white-space: nowrap;
    position: relative;
    z-index: 0;
}

.Tabla-Adm-Control thead tr{
    width: 1148px;
    height: 56px;
    left: 60px;
    top: 244px;
    background: #1EC0ED;
    font-weight: 700;
    font-size: 14px;
    line-height: 172.51%;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

.Tabla-Adm-Control tbody tr{
    width: 1148px;
    height: 37px;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Tabla-Adm-Control thead tr th:first-child{
    border-top-left-radius: 8px;
}

.Tabla-Adm-Control thead tr th:last-child{
    border-top-right-radius: 8px;
}

.Paginacion-Control-Archivo{
    /* padding-right: 44px; */
    display: flex;
    justify-content: flex-end;
    /* padding-top: 19px; */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;  
    color: #1E1E1E;
    align-items: center;
}

.Tabla-Adm-Control tbody tr td:nth-child(2){
    text-align: start !important;
}

.Texto-Columna-Archivo{
    width: 190px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Contenedor-Columna-Archivo{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.Contenedor-Cabecera-Archivo{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Texto-Columna-Nombre{
    width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.Busqueda-Control-Archivos{
    width: 100%;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 27px !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #A4A3A3;
    padding: 0px 14px;
    border-color: #000000 !important;
}

.Busqueda-Control-Archivos:hover{
    border-color: #000000 !important;
}

.Input-Buscar-Control-Archivo .ant-input-affix-wrapper:focus{
    box-shadow: none !important;
    border-color: #000000 !important;
}

.Input-Buscar-Control-Archivo .ant-input-affix-wrapper-focused{
    box-shadow: none !important;
    border-color: #000000 !important;
}

@media screen and (max-width: 1437px) {
    .Input-Buscar-Control-Archivo{
        width: 58% !important;
    }
}

.Btn-Tipo-Carga{
    /* width: 84px; */
    height: 36px;
    border: 1px solid #1E1E1E;
    box-sizing: border-box;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    cursor: pointer;
    margin-right: 18px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.Contenedor-Filtro-TipoCarga{
    position: absolute;
    width: 130%;
    height: 180px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
    border-radius: 0px 0px 8px 8px;
    top: 36px;
    left: 0;
    z-index: 1;
    padding-left: 10px;
    padding-right: 10px;
}

.Titulo-Filtro-TipoCarga{
    margin-top: 7px;
    margin-bottom: 5px;
}

.Check-Filtro-TipoCarga .ant-checkbox{
    width: 12px;
}

.Cuerpo-Filtro-TipoCarga{
    width: 100%;
    height: 115px;
    overflow-y: auto;
}

.Btns-Filtro-TipoCarga{
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 6px;
    width: 90%;
}

.Btn-Aceptar-Filtro-TipoCarga{
    background: #3646C3;
    border: 1px solid #3646C3;
    box-sizing: border-box;
    border-radius: 14px;
    width: 52.99px;
    height: 19.27px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    cursor: pointer;
}

.Btn-Cancelar-Filtro-TipoCarga{
    border: 1px solid #1E1E1E;
    box-sizing: border-box;
    border-radius: 14px;
    width: 52.99px;
    height: 19.27px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    cursor: pointer;
}