.Campos-Perfil{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    /* align-items: center; */
    text-transform: capitalize;
    color: #1E1E1E;
    margin-top: 7px;
}

.Input-Perfil{
    /* width: 517px; */
    width: 100%;
    height: 29px !important;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 8px !important;
    padding: 0px 8px;
    font-family: Segoe UI;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
}
.InputFormPerfil .ant-form-item-control-input-content{
    height: 27px !important;
}

.Primera-Columna-Perfil{
    display: flex !important;
}

.Segunda-Columna-Perfil{
    display: flex !important;
    justify-content: flex-end;
}

.Boton-Guardar-Perfil{
    width: 136px;
    height: 28px;
    background: #3646C4;
    border-radius: 16px;
    border: none;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    cursor: pointer;
}

.Boton-Cancelar-Perfil{
    width: 138px;
    height: 28px;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 18px;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1E1E1E;
    cursor: pointer;
}

.Foto-Perfil{
    width: 58px;
    height: 58px;
    background: white;
    cursor: pointer;
}

.imagen-upload > input
{
    display: none;
}

#file-input{
    display: none;
}

.Select-Perfil .ant-select-item .ant-select-item-option-content{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}

.Text-Inputs-Contrasenia {
    font-family: Segoe UI;
    font-style: normal;
    /* font-weight: 600; */
    font-size: 12px;
    line-height: 16px;
    display: flex;
    text-transform: capitalize;
    color: #1EC0ED;
    /* margin-top: 7px; */
}