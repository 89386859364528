.Banner-Noticias-Promociones{
    width: 853px;
    height: 436px;
    margin-top: -26px;
    margin-left: -35px;
    margin-right: -35px;
    margin-bottom: -25px;
}

.Cont-Promociones .ant-modal-content{
    background: transparent !important;
}

.Cont-Modal-Txt-Promociones{
    /* background-color: red; */
    width: 420px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    align-items: center;

    padding-right: 25px;
}

.Btn-Entendido-Modal-Promociones{
    width: 84px;
    height: 36px;
    background: #000000;
    border-radius: 23px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.Btn-Entendido-Modal-Promociones:hover{
    background-color: white;
    color: black;
}

.Btn-Prom-Nueva{

    border: 1px solid #1E1E1E;
    box-sizing: border-box;
    border-radius: 8px;
    padding-top: 7px;
    padding-bottom: 5px;
    position: relative;
    padding-left: 26px;
    padding-right: 8px;
    margin-left: 20px;

    cursor: pointer;
}

.Btn-Prom-Nueva:hover{
    border: 2px solid #FEDD34;
    color: #FEDD34 !important;
}

.Btn-Prom-Nueva:hover .W600-S14-H19-C1E1E1E-L0015{
    color: #FEDD34 !important;
}

.Gif-Prm-Nuevas-Promociones{
    width: 28px;
    position: absolute;
    left: 0;
    top: 4px;
    border-radius: 8px;
    z-index: -1;
}

.Cont-Gif-Nuevas-Promociones{
    /* background-color: yellow; */
    position: absolute;
    /* width: 60px;
    height: 60px;
    border-radius: 500px; */
    border-radius: 500px;
    border: 2px solid #FEDD34;
    width: 40px;
    height: 40px;
    z-index: 2;
    top: 10px;
    left: 20px;
    
}

.Gif-Nuevas-Promociones{
    width: 26px;
    position: absolute;
    left: 5px;
    top: 5px;
    z-index: 0;
}