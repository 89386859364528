:root {
    --color-primary: #6B7A8F;
    --color-secondary: #101118;
    --color-accent: #1D1F2F;
    --color-focus: #6D64F7;
    --base-duration: 600ms;
    --base-ease: cubic-bezier(0.25, 0.46, 0.45, 0.84);
  }
  
  // =========================
  // Global
  // =========================
  
  // #app {
  //   align-items: center;
  //   display: flex;
  //   height: 100%;
  //   justify-content: center;
  //   overflow-x: hidden;
  //   width: 100%;
  // }
  
  
  
  .visuallyhiddenPromocion { 
    clip: rect(1px, 1px, 1px, 1px);  
    height: 1px; 
    overflow: hidden;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
  }
  
  
  // =========================
  // Icons
  // =========================
  
  // .icon {
  //   fill: var(--color-primary);
  //   width: 100%;
  // }
  
  
  // =========================
  // Buttons
  // =========================
  
  // .btn {
  //   background-color: var(--color-primary);
  //   border: none;
  //   border-radius: 0.125rem;
  //   color: white;
  //   cursor: pointer;
  //   font-size: inherit;
  //   padding: 1rem 2.5rem 1.125rem;
    
  //   &:focus {
  //     outline-color: var(--color-focus);
  //     outline-offset: 2px;
  //     outline-style: solid;
  //     outline-width: 3px;
  //   }
    
  //   &:active {
  //     transform: translateY(1px);
  //   }
  // }
  
  
  // =========================
  // Slider controls
  // =========================
  
  // .slider__controls {
  //   display: flex;
  //   justify-content: center;
  //   position: absolute;
  //   top: calc(100% + 1rem);
  //   width: 100%;
    
  //   .btn {
  //     --size: 3rem;
      
  //     align-items: center;
  //     background-color: transparent;
  //     border: 3px solid transparent;
  //     border-radius: 100%;
  //     display: flex;
  //     height: var(--size);
  //     padding: 0;
  //     width: var(--size);
      
  //     &:focus {
  //       border-color: var(--color-focus);
  //       outline: none;
  //     }
          
  //     &--previous > * {
  //       transform: rotate(180deg);
  //     }
  //   }
  // }
  
  
  // =========================
  // Slider
  // =========================
  
  .sliderPromocion{
    --slide-size: 40vmin;
    // --slide-size: 100vmin;
    --slide-margin: 0vmin;
    
    // height: var(--slide-size);
    height: 260px;
    // margin: 0 ;
    position: relative;
    width: 100%;
  }
  
  .slider__wrapper_promocion {
    display: flex;
    margin: 0 calc(var(--slide-margin) * -1);
    position: absolute;
    transition: transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
  }
  
  
  // =========================
  // Slide
  // =========================
  
  .slidePromocion {
    // align-items: center;
    // color: white;
    display: flex;
    // flex: 1;
    // flex-direction: column;
    height: var(--slide-size);
    // justify-content: center;
    margin: 0 var(--slide-margin);
    // opacity: 0.25;
    position: relative;
    // text-align: center;
    transition: 
      opacity calc(var(--base-duration) / 2) var(--base-ease),
      transform calc(var(--base-duration) / 2) var(--base-ease);
    // width: var(--slide-size);
    width: 400px;
    z-index: 1;
    
    // &--previous,
    // &--next {    
      // &:hover {
        // opacity: 0.5;
      // }
    // }
    
    &--previous {
      cursor: w-resize;
      
      &:hover {
        transform: translateX(2%);
      }
    }
    
    &--next {
      cursor: e-resize;
      
      &:hover {
        transform: translateX(-2%);
      }
    }
  }
  
  .slide--currentPromocion {
    --x: 0;
    --y: 0;
    --d: 50;
  
    opacity: 1;
    pointer-events: auto;
    user-select: auto;
    
    @media (hover: hover) {
      &:hover .slide__image-wrapperPromocion {
        transform: 
          scale(1.025)
          translate(
            calc(var(--x) / var(--d) * 1px),
            calc(var(--y) / var(--d) * 1px)
          );
      }    
    }
  }
  
  .slide__image-wrapperPromocion {
    // background-color: var(--color-accent);
    // border-radius: 1%;
    // height: 100%;
    // left: 0%;
    // overflow: hidden;
    // position: absolute;
    // top: 0%;  
    // margin-left: -30px; // MARGIN PARA ACERCARSE AL CANAL
    transition: transform calc(var(--base-duration) / 4) var(--base-ease);
    width: 100%;
  }
  
  // .slide__image {
  //   --d: 20;
    
  //   height: 110%;
  //   left: -5%;
  //   object-fit: cover;
  //   // opacity: 0;
  //   pointer-events: none;
  //   position: absolute;
  //   top: -5%;
  //   transition:
  //     // opacity var(--base-duration) var(--base-ease),
  //     transform var(--base-duration) var(--base-ease);
  //   user-select: none;
  //   width: 110%;
    
  //   @media (hover: hover) {
  //     .slide--current & {      
  //       transform: 
  //         translate(
  //           calc(var(--x) / var(--d) * 1px),
  //           calc(var(--y) / var(--d) * 1px)
  //         ); 
  //     }
  //   }
  // }
  
  .slide__headlinePromocion {
    font-size: 8vmin;
    font-weight: 600;
    position: relative;
  }
  
  .slide__contentPromocion {
    --d: 60;
    
    // opacity: 0;
    padding: 4vmin;
    position: relative;
    transition: transform var(--base-duration) var(--base-ease);
    visibility: hidden;
    
    .slide--currentPromocion & {
      animation: fade-in calc(var(--base-duration) / 2) var(--base-ease) forwards;
      visibility: visible;
      
      @media (hover: hover) {
        transform: 
          translate(
            calc(var(--x) / var(--d) * -1px),
            calc(var(--y) / var(--d) * -1px)
          );
      }
    }
  
  }
  
  #tituloCombos{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
  }

  #tituloVenta{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 13px;
    color: #FFBB37;
  }

  #txtgratis{
    color:white;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    // line-height: 11px;
  }

  #imggratis{
    // padding-bottom: 1px;
    width: 10px;
    height: 10px;
  }

#txtProducto{
    margin-top: 5px;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    color: #1E1E1E;
}

  #entornoGratis{
    background: #F93258;
    border-radius: 10px;
    width: 60px;
    height: 16px;
    // padding-bottom: 1px;
    // padding-top: 3px;
    margin-left: 40px;
    color:white;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 9px;
    line-height: 11px;
    padding-top: 2px;
  }

  #entornoGratisSinColor{
    background: transparent;
    border-radius: 10px;
    width: 60px;
    height: 16px;
    // padding-bottom: 1px;
    // padding-top: 3px;
    margin-left: 40px;
    color:white;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 9px;
    line-height: 11px;
    padding-top: 2px;
  }

#tituloValorizado{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    color: #1E1E1E;
}

#precioValorizado{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    line-height: 14px;
    color: #1E1E1E;
}

#txtPlanchas{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    color: #1E1E1E;
}

#inputPlanchas{
    width: 50px;
    height: 11px;
    border-style: solid;
    outline: none;
    border-top: 0px dotted transparent;
    border-left: 0px dotted transparent;
    border-right: 0px dotted transparent;
    border-bottom: 1px solid #101118;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    -webkit-appearance: none; 
    margin: 0; 
}

#inputPlanchas::-webkit-outer-spin-button,
#inputPlanchas::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#inputPlanchas{
    -moz-appearance:textfield;
}

#inputPlanchasBloqueado{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    line-height: 14px;
    color: #1E1E1E;
}

#txtBtnEditar{
    // border:1px solid #2ABEE0;
    color: #ffff;
    border-radius: 25px;
    height: 21px;
    width: 71px;

    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 13px;
    color: #FFFFFF;
}

  #txtBtnEditar:hover{
    border: none;
  }

  #felicidades{
    // style={{color:'white', fontWeight:'500', fontSize:'38px'}}
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
    color: white;
  }
  
  #decripcionFelicidades{
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    color: white;
  }

  #tituloConfirmacion{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 12px;
    color: white;
  }

  #descripcionConfirmacion{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    margin-top: 15px;
  }

  #btnEditar{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 900;
    font-size: 11px;
    line-height: 13px;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 15px;
    height: 25px;
  }
  // =========================
  // Animations
  // =========================
  
  @keyframes fade-in {
    from { opacity: 0 }
    to   { opacity: 1 }
  }

  #insigniaCompletado{
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 20px;
    margin-top: 10px;
    background: #FBB03B;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    line-height: 11px;
    font-size: 9px;
  }

  #upsPromocionHover{
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
  }

  #tuCierrePromocionHover{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
  }

  
.contenedorSliderPromocion{
  margin-top: 220px;
  // background: red;
  position: absolute;
  width: 100%;
  height: 0px;
}

#primeraMitadSliderPromocion{
  float: left;
  // background: blue;
  width: 80px;
  height: 300px;
  margin-top: -200px;
  margin-left: -45px;
}
#segundaMitadSliderPromocion{
  float: right;
  // background: black;
  width: 80px;
  height: 300px;
  margin-top: -200px;
  margin-right: -15px;
}

.minorista{
  // padding-left: 10px;
  display: inline-block;
  position: absolute;
  left: -50px;
  width: 50px;
  height: 270px;
  top: 0px;
  // writing-mode: vertical-lr;
  // text-orientation: upright;
  text-align: center;
  // text-transform: uppercase;
  border-radius: 20px 20px 20px 20px;
  text-align: center;
  padding-top: 130px;

  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.015em;
  color: #FFFFFF;

}

#txtcanal{

  // transform-origin: 0 0;
  // background-color: green;
  transform: rotate(270deg);
  // color: rebeccapurple;
  // @include transform(rotate(270deg));
  // @include transform-origin(0 0);
}

#ultimaColumnaCarouselPromocion{
  right: 0px;
  top: 0px;
  // position: absolute;
  width: 10px;
  height: 100%;
  // background: rebeccapurple;
}

#txtSubProducto{
    
    // color: #A8A6A6;
    margin-bottom: 7px;

    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    color: #1E1E1E;
}

#txt_totalPlanchas{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    // opacity: 0.7;
    margin-top: -3px;
}

#btnEditarPromocion{
  
  cursor: pointer;
}

#iconoEditar{
  
}

#iconoCancelarEdicion{
  font-size: 35px;
}

#iconoCancelarEdicionPequeno{
  font-size: 25px;
  color: red;
}

#iconoGuardarEdicion{
  font-size: 25px;
  color: green;
}

#contenedorIconosEdicion{
  // background: red;
  width: 200px;
}

#contenedorBtnEditarPromocion{
  position: absolute;
  z-index: 1;
}

#contenedorPalabraCodigoPromocion{
  position: absolute;
  z-index: 1;
  bottom: 0.0;
}

#conteno{
  // position: relative;

}

#contenedorImagenProducto{
    position: relative;
    width: 100%;
    height: 59px;
    cursor: pointer;
  
}

#contenedorImagenProductoBonificado{
  position: relative;
  width: 100%;
  height: 59px;
  cursor: pointer;
}

#iconoEditarProducto{
  position: absolute;
  right: 10px;
  color: #F93258;
}

#insigniaNuevo{
  position: absolute;
  left: 0;
  top: 0;
  margin-top: -15px;
  margin-left: 10px;
}