.Contenedor-Status{
    /* margin-top: 100px; */
    margin-bottom: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Contenedor-Datos-Superior{
    display: flex;
    justify-content: center;
}

.Caja-Opciones-Datos{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 202px;
    height: 57px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px #E5E5E5;
    border-radius: 8px;
}

.Txt-Titulos-Cajas{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Txt-Porcentaje-Cajas{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.015em;
    color: #000000;
}

.Contenedor-filtros{
    display: flex;
    padding-left: 68px;
}