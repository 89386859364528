.Contenedor-Fecha {
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 6px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Contenedor-Fecha-Texto{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    margin: 7px 28px;
}

.Contenedor-Mes-Global{
    width: 657px;
    height: 210px;
    background: #FFFFFF;
    box-shadow: 0px 3px 8px #C4C4C4;
    border-radius: 0px 0px 8px 8px;
    position: absolute;
    left: 550px;
    padding: 10px;
}

.Contenedor-Mes{
    padding-left: 5px;
    padding-right: 5px;
}
/* .dias{
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important;
    grid-template-rows: repeat(5, 1fr) !important;
} */

.Dias-Nombres{
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important;
    justify-items: center;
}

.Dias-Nombres-Texto{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    text-transform: uppercase;
    color: #1E1E1E;
}

.Meses-Texto{
    font-family: 'Roboto', sans-serif;
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    text-transform: capitalize;
    color: #1E1E1E;
    justify-content: center;
}

.Calendario .Dia{
    width: calc(100% / 7);
    display: inline-block;
    text-align: center;
}

.Calendario > div .Dia:first-child{
    color: #CCCCCC;
}

.Calendario > div .Dia:last-child{
    color: #CCCCCC;
}

.Dia-Seleccionado {
    width: 24.93px;
    height: 24.93px;
    border-radius: 17px;
    padding-top: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.Dia-Seleccionado:hover{
    background: #FFFF00;
    font-weight: bold;
}

.Dia{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;

    color: #1E1E1E;
}

#Dia-Actual{
    background: #FFFF00;
    font-weight: bold;
}