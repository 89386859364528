.Titulo-Elementos{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 35px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    padding-left: 40px;
    padding-top: 19px;
}

.Paginacion-Elementos{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    display: flex;
    justify-content: flex-end;
    padding-right: 69px;
    padding-top: 34px;
}

.Responsive-Tabla table{
    width: 100%;
}

.Responsive-Tabla {
    /* padding-left: 40px;
    padding-right: 69px; */
}

.Contenedor-Elementos-Enviados{
    margin-top: 100px;
    margin-bottom: 250px;
}

.Responsive-Tabla table tbody tr {
    height: 38px;
    border-bottom: 1px solid #C4C4C4;
    
}

.Responsive-Tabla table tbody tr td:first-child{
    text-align: center;
}

.Responsive-Tabla table tbody tr td:nth-child(2){
    display: flex;
    padding-top: 10px;
    align-content: center;
    padding-left: 180px;
}

.Primera-Columna {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
}

.Caja-Segunda-Columna{
    width: 73px;
    height: 18px;
    background: #FF3742;
    border-radius: 3px;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    text-align: center;
}

.Texto-Segunda-Columna{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    margin-left: 17px;

    display: flex;
}

.Texto-Paginacion{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Flecha-Derecha {
    width: 9px;
    height: 9px;
    cursor: pointer;
}

.Flecha-Izquierda {
    width: 9px;
    height: 9px;
    transform: rotate(-180deg);
    margin-right: 17px;
    cursor: pointer;
}

.Icono-Reenviar{
    height: 13px;
    width: 15px;
    cursor: pointer;
}

.Icono-Eliminar{
    height: 17px;
    width: 15px;
    cursor: pointer;
}

.Fondo-Hover-Icono1{
    background: white;
    width: 36px;
    border-radius: 21px;
    display: flex;
    height: 36px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-right: 3px;
    cursor: pointer;
}

.Fondo-Hover-Icono1:hover{
    background: #558CFF;
}

.Fondo-Hover-Icono1:hover .Icono-Reenviar{ 
    content:url(../../../Assets/Img/ElementosEnviados/Reenviar-white.png);
}

.Fondo-Hover-Icono2{
    background: white;
    width: 36px;
    border-radius: 21px;
    display: flex;
    height: 36px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.Fondo-Hover-Icono2:hover{
    background: #558CFF;
}

.Fondo-Hover-Icono2:hover .Icono-Eliminar{ 
    content:url(../../../Assets/Img/ElementosEnviados/Eliminar-white.png);
}

.Campo-Opciones{
    display: flex;
    justify-content: center;
    padding-top: 7px;
}

.Texto-Asunto{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    /* padding-left: 40px; */
    margin-top: 9px;
}

.Icono-Persona{
    width: 32px;
}

.Caja-Asunto{
    border-bottom: 1px solid #C4C4C4;
    margin-left: 40px;
    margin-right: 69px;
}

.Caja2-Asunto{
    margin-top: 11px;
    margin-left: 40px;
    margin-right: 69px;
}

.De-Para-Texto{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

/* .De-Para-Texto:hover{
    cursor: pointer;
} */

.Zona-Icono{
    display: flex;
    justify-content: center;
}

/* .Zona-Icono:hover{
    cursor: pointer;
} */

.Caja-Modal-ReenviarElemento .ant-modal-content{
    border-radius: 8px;
}

.Contenedor-Botones-Modal{
    display: flex;
    justify-content: center;
}

.Boton-Cancelar-Eliminar-Modal{
    width: 67px;
    height: 24px;
    border: 1px solid #1E1E1E;
    box-sizing: border-box;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    background: white;
}

.Boton-Aceptar-Eliminar-Modal{
    width: 66px;
    height: 24px;
    border: 1px solid #3646C3;
    box-sizing: border-box;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    background: #3646C4;
    cursor: pointer;
}

.Titulo-Modal-Reenviar-Elemento{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.Texto-Modal-Reenviar-Elemento{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
}

.TxtArea-Reenviar-Elemento{
    border: none;
}

.TxtArea-Reenviar-Elemento:focus{
    border: none;
    outline: none;
}


.Icono-Excel-Transparente{
    width: 32px;
    height: 32px;
    margin-right: 10px;
    margin-top: 5px;
}

.Contenedor-Archivo-Excel-Elementos-Enviados{
    border: 1px solid #C4C4C4;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    display: flex;
    padding-left: 5px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 5px;
}

.Celda-Lista-Sucursales-Elementos-Enviados:hover .Contenedor-Lista-Sucursales-Elementos-Enviados{
    display:block;
}

.Contenedor-Lista-Sucursales-Elementos-Enviados{
    display: none;
    position: absolute;
    width: 350px;
    /* height: 100px; */
    background-color: white;
    box-shadow: 0 4px 4px rgb(216 223 233 / 92%);
    border-radius: 15px;
    right: -340px;
    top: 0;
    z-index: 1;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}