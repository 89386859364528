.Contenedor-Tabla-Status{
    width: 1200px;
    height: 100%;  
    overflow-x: hidden;
    border-radius: 8px;
}

.Tabla-Status{
    width: 100%;
    text-align : center;
    overflow:scroll;
    white-space: nowrap;
    position: relative;
    z-index: 0;
}

.Tabla-Status tbody tr{
    animation-delay: 3s;
}

.Tabla-Status thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

.Tabla-Status thead tr th{
    padding: 0px 12px;
}

.Tabla-Status thead tr{
    width: 402px;
    height: 56px;
    left: 60px;
    top: 244px;
    background: #1EC0ED;
    font-weight: 700;
    font-size: 14px;
    line-height: 172.51%;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

.Tabla-Status tbody tr{
    height: 23px;
    background: rgba(237, 240, 250, 0.7);
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Circulo-Dias-Rojo{
    background: red;
    width: 15px;
    height: 15px;
    border-radius: 8px;
}

.Circulo-Dias-Verde{
    background: #2FDA36;
    width: 15px;
    height: 15px;
    border-radius: 8px;
}


.Columna-Dias-Retraso{
    display: flex;
    align-items: center;
    justify-content: center;
}

.Txt-Detalle{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1EC0ED;
    cursor: pointer;
}

.Rojo{
    color: red;
}

.Verde{
    color: #2FDA36;
}

.prueba{
    color: black !important;
}