.Contenedor-Modal-Datos{
    display: flex;
    justify-content: center;
}

.Btn-Seleccionado-Caja-Promociones{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 241px;
    height: 45px;
    background: #558CFF;
    box-shadow: 0px 4px 4px #E5E5E5;
    border-radius: 8px;
    cursor: pointer;
    color: #FFFFFF;

}

.Btn-NoSeleccionado-Caja-Promociones{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 241px;
    height: 45px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px #E5E5E5;
    border-radius: 8px;
    cursor: pointer;
    color: #000000;
    border: 1px solid #558CFF;
}

.Txt-Caja-Promociones{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;

}

.Numero-Caja-Promociones{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.015em;
}

.Caja-Lima{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100px;
    height: 45px;
    border: 1px solid #FA65E2;
    filter: drop-shadow(0px 4px 4px #E5E5E5);
    border-radius: 8px;
}

.Txt-Caja-Lima{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
}

.Numero-Caja-Lima{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.015em;
}

.Btn-Seleccionado-Caja-Lima{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100px;
    height: 45px;
    background: #84A6FF;
    border: 1px solid #84A6FF;
    filter: drop-shadow(0px 4px 4px #E5E5E5);
    border-radius: 8px;
    cursor: pointer;
    color: #FFFFFF;
}

.Btn-NoSeleccionado-Caja-Lima{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100px;
    height: 45px;
    border: 1px solid #84A6FF;
    background: #FFFFFF;
    filter: drop-shadow(0px 4px 4px #E5E5E5);
    border-radius: 8px;
    cursor: pointer;
    color: #000000;
}

.Btn-Seleccionado-Caja-Norte{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100px;
    height: 45px;
    border: 1px solid #80FFA3;
    background: #80FFA3;
    filter: drop-shadow(0px 4px 4px #E5E5E5);
    border-radius: 8px;
    cursor: pointer;
    color: #FFFFFF;
}

.Btn-NoSeleccionado-Caja-Norte{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #80FFA3;
    filter: drop-shadow(0px 4px 4px #E5E5E5);
    border-radius: 8px;
    cursor: pointer;
    color: #000000;
}

.Btn-Seleccionado-Caja-Sur{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100px;
    height: 45px;
    background: #FFAEAE;
    border: 1px solid #FFAEAE;
    filter: drop-shadow(0px 4px 4px #E5E5E5);
    border-radius: 8px;
    cursor: pointer;
    color: #FFFFFF;
}

.Btn-NoSeleccionado-Caja-Sur{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #FFAEAE;
    filter: drop-shadow(0px 4px 4px #E5E5E5);
    border-radius: 8px;
    cursor: pointer;
    color: #000000;
}

.Contenedor-Modal-Tabla-Status{
    /* margin-left: 40px; */
    width: 923px;
    height: 300px;  
    overflow-x: hidden;
    border-radius: 8px;
    
}

.Modal-Tabla-Status{
    width: 100%;
    text-align : center;
    overflow:scroll;
    white-space: nowrap;
    position: relative;
    z-index: 0;
}

.Modal-Tabla-Status tbody tr{
    animation-delay: 3s;
}

.Modal-Tabla-Status thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

.Modal-Tabla-Status thead tr th{
    padding: 0px 12px;
}

.Modal-Tabla-Status thead tr{
    width: 402px;
    height: 45px;
    left: 60px;
    top: 244px;
    background: #1EC0ED;
    font-weight: 700;
    font-size: 14px;
    line-height: 172.51%;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

.Modal-Tabla-Status tbody tr{
    height: 23px;
    background: rgba(237, 240, 250, 0.7);
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}
