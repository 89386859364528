.Titulo-Elementos-Enviados{
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    letter-spacing: -0.015em;
    color: #3646C3;
    padding-left: 40px;
    padding-top: 19px;
}

.Btn-Elementos-Enviados{
    /* width: 84px; */
    height: 36px;
    border: 1px solid #1E1E1E;
    box-sizing: border-box;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    cursor: pointer;
    margin-right: 18px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.Contenedor-Btn-Elementos-Enviados{
    display: flex;
    margin-top: 33px;
    margin-left: 40px;
}

.Paginacion-Elementos-Enviados{
    margin-top: 33px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 69px;
}

.Contenedor-Tabla-Elementos-Enviados{
    margin-top: 33px;
    /* margin-left: 227px; */
    width: 1180px;
    border-radius: 8px;
}

.Tabla-Elementos-Enviados{
    width: 100%;
    text-align : center;
    white-space: nowrap;
    position: relative;
    z-index: 0;
}

.Tabla-Elementos-Enviados thead tr{
    width: 880px;
    height: 56px;
    left: 60px;
    top: 244px;
    background: #1EC0ED;
    font-weight: 700;
    font-size: 14px;
    line-height: 172.51%;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

.Tabla-Elementos-Enviados tbody tr{
    width: 1148px;
    height: 37px;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Tabla-Elementos-Enviados thead tr th:first-child{
    border-top-left-radius: 8px;
}

.Tabla-Elementos-Enviados thead tr th:last-child{
    border-top-right-radius: 8px;
}

.Fondo-Icono-Reenviar{
    background: #FFFFFF;
    border-radius: 14px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.Fondo-Icono-Eliminar{
    background: #FFFFFF;
    border-radius: 14px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.Fondo-Icono-Reenviar:hover{
    background: #558CFF;
}

.Fondo-Icono-Eliminar:hover{
    background: #558CFF;
}

.Fondo-Icono-Reenviar:hover .Icono-Reenviar{ 
    content:url(../../../Assets/Img/ElementosEnviados/Reenviar-white.png);
}

.Fondo-Icono-Eliminar:hover .Icono-Eliminar{ 
    content:url(../../../Assets/Img/ElementosEnviados/Eliminar-white.png);
}

.Busqueda-Elementos-Enviados{
    width: 100%;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 27px !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #A4A3A3;
    padding: 0px 14px;
    border-color: #000000 !important;
}

.Busqueda-Elementos-Enviados:hover{
    border-color: #000000 !important;
}

.Input-Buscar-Elementos-Enviados .ant-input-affix-wrapper:focus{
    box-shadow: none !important;
    border-color: #000000 !important;
}

.Input-Buscar-Elementos-Enviados .ant-input-affix-wrapper-focused{
    box-shadow: none !important;
    border-color: #000000 !important;
}

.Contenedor-Filtro-TipoEnvio{
    position: absolute;
    width: 220px;
    height: 180px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
    border-radius: 0px 0px 8px 8px;
    top: 36px;
    left: 0;
    z-index: 1;
    padding-left: 10px;
    padding-right: 10px;
}

.Titulo-Filtro-TipoEnvio{
    margin-top: 7px;
    margin-bottom: 5px;
}

.Cuerpo-Filtro-TipoEnvio{
    width: 100%;
    height: 115px;
    overflow-y: auto;
}

.Btns-Filtro-TipoEnvio{
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 6px;
    width: 90%;
}

.Btn-Aceptar-Filtro-TipoEnvio{
    background: #3646C3;
    border: 1px solid #3646C3;
    box-sizing: border-box;
    border-radius: 14px;
    width: 52.99px;
    height: 19.27px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    cursor: pointer;
}

.Btn-Cancelar-Filtro-TipoEnvio{
    border: 1px solid #1E1E1E;
    box-sizing: border-box;
    border-radius: 14px;
    width: 52.99px;
    height: 19.27px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    cursor: pointer;
}

.Check-Filtro-TipoEnvio .ant-checkbox{
    width: 12px;
}