.Contenedor-Cuerpo-Filtro-Zonas-Ventas-Promociones{
    /* width: 850px; */
    width: 93vw;
    height: 430px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 8px 8px;
    position: absolute;
    top: 40px;
    overflow-y: auto;
    display: flex;
    white-space: nowrap;
    padding-top: 10px;
    z-index: 2;
}

.Fila-Filtro-Zona-Ventas-Promociones{
    padding-bottom: 7px;
    padding-top: 7px;
    padding-left: 20px;
    padding-right: 25px;
    cursor: pointer;
    display: flex;
    position: relative;
    width: 190px;
    height: 30px;
    /* background-color: red; */
}

.Fila-Filtro-Zona-Ventas-Promociones:hover{
    background: #E6F1FC;
}

.Item-Sucursal-Filtro{
    cursor: pointer;
    /* padding-top: 5px; */
    padding-bottom: 5px;
    display: flex;
    position: relative;
    width: 190px;
    height: 30px;
}

.Borde-Filtro-Contenedor{
    width: 140px;
    height: 36px;
    border: 1px solid #1E1E1E;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    display: flex;
    padding-right: 50px;
    padding-left: 10px;
    padding-top: 7px;
    margin-right: 15px;
    cursor: pointer;
    z-index: 0;
    background: white;

}

.Linea-Filtro-Zonas-Ventas-Promociones{
    width: 15px;
    height: 100%;
    background: #F1F1F1;
    color: transparent;
    margin-top: -17px;
}

.Contenedor-Btn-Filtro-Aplicado-FiltroVentas{
    /* width: 129px; */
    height: 27px;
    background: #EDF0FA;
    border-radius: 21px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding-left: 15px;
    padding-right: 50px;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
}

.Contenedor-Btn-Filtro-Aplicado-FiltroVentas:hover .Icono-Cerrar-Casilla-FiltroVentasPromociones{
    color: #3646C3 !important;
}


.Contenedor-Btn-Filtro-Aplicado-FiltroVentas:hover .Icono-Cerrar-Azul-Dt-FiltroVentas{
    display: flex;
}

.Contenedor-Btn-Filtro-Aplicado-FiltroVentas:hover .Icono-Cerrar-Dt-FiltroVentas{
    display: none;
}

.Contenedor-Botones-Filtro-Aplicados{
    /* background-color: red; */
    /* width: 100%; */
    display: flex;
    overflow-x: auto;
    position: relative;
    transition: 2s all ease;
    margin-right: 60px;
    margin-left: 20px;
}

.Contenedor-Botones-Filtro-Aplicados::-webkit-scrollbar {
    display: none;
}

.Flecha-Avanzar-Scroll-Filtro-Aplicados{
    position: absolute;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    background: #E5E5E5;
    right: 30px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
}

.Flecha-Avanzar-Scroll-Filtro-Aplicados:hover{
    background: #558CFF;
}

.Flecha-Avanzar-Scroll-Filtro-Aplicados:hover .Icono-Avanzar-FiltroVentaPromociones{
    display: none;
}

.Flecha-Avanzar-Scroll-Filtro-Aplicados:hover .Icono-Avanzar-FiltroVentaPromociones-Blanco{
    display: flex;
}

.Flecha-Retroceder-Scroll-Filtro-Aplicados{
    position: absolute;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    background: #E5E5E5;
    left: 100px;
    top: -3px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
}

.Flecha-Retroceder-Scroll-Filtro-Aplicados:hover{
    background: #558CFF;
}

.Flecha-Retroceder-Scroll-Filtro-Aplicados:hover .Icono-Retroceder-FiltroVentaPromociones{
    display: none;
}

.Flecha-Retroceder-Scroll-Filtro-Aplicados:hover .Icono-Retroceder-FiltroVentaPromociones-Blanco{
    display: flex;
}

.Linea-Filtro-Zona-Venta-Promociones{
    background-color: transparent;
    /* background-color: red; */
    width: 100%;
    height: 40px;
    position: absolute;
    z-index: 5;
    margin-top: -35px;
}

.Icono-Avanzar-FiltroVentaPromociones{
    width: 6px;
}

.Icono-Avanzar-FiltroVentaPromociones-Blanco{
    width: 13px;
    transform: rotate(-90deg);
    margin-right: -2px;
    margin-top: 1px;
    display: none;
}

.Icono-Retroceder-FiltroVentaPromociones{
    width: 6px;
    transform: rotate(-180deg);
}


.Icono-Retroceder-FiltroVentaPromociones-Blanco{
    display: none;
    width: 13px;
    transform: rotate(90deg);
    margin-left: -2px;
    margin-top: 1px;
}

.Icono-Cerrar-Dt-FiltroVentas{
    width: 10px;
}

.Icono-Cerrar-Azul-Dt-FiltroVentas{
    width: 11px;
    display: none;
}

.Check-FiltroZonaVentasPromociones .ant-checkbox-checked .ant-checkbox-inner{
    background-color: #558CFF !important;
    border: 1px solid #558CFF;
}


.Check-FiltroZonaVentasPromociones .ant-checkbox-inner{
    border: 1px solid #558CFF !important;
}