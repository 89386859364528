.Btn-Crear-Administrativo-Usuario{
    width: 82px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #558CFF;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.Texto-Btn-Adm-Usuarios {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #558CFF;
    margin-right: 6px;
}

.Contenedor-Btn-Adm-Usuarios {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 11px;
    padding-right: 58px;
}

.Contenedor-Tabla-Adm-Usuarios{
    margin-left: 40px;
    width: 490px;
    height: 394px;  
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 8px;
}

.Tabla-Adm-Usuarios{
    width: 100%;
    text-align : center;
    overflow:scroll;
    white-space: nowrap;
    position: relative;
    z-index: 0;
}

.Tabla-Adm-Usuarios tbody tr{
    animation-delay: 3s;
}

.Tabla-Adm-Usuarios tbody tr:hover{
    background: #E6F1FC;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px #E5E5E5;
    cursor: pointer;
}

.Tabla-Adm-Usuarios thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

.Tabla-Adm-Usuarios thead tr th{
    padding: 0px 12px;
}

.Tabla-Adm-Usuarios thead tr{
    width: 402px;
    height: 56px;
    left: 60px;
    top: 244px;
    background: #1EC0ED;
    font-weight: 700;
    font-size: 14px;
    line-height: 172.51%;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

.Tabla-Adm-Usuarios tbody tr{
    height: 37px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Cabecera-Crear-Adm-Usuario{
    /* width: 773px; */
    height: 56px;
    background: #1EC0ED;
    border-radius: 8px 8px 0px 0px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    padding-left: 49px;
}

.Cuerpo-Crear-Adm-Usuario{
    /* width: 773px; */
    height: 350px;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
}

.CampoA-Crear-Adm-Usuario{
    display: flex;
    justify-content: space-between;
    padding-left: 38px;
    margin-top: 16px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    align-items: center;
}
.Campo2-Crear-Adm-Usuario{
    display: flex;
    justify-content: space-between;
    padding-right: 51px;
    margin-top: 16px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    align-items: center;
    padding-left: 20px;
}

.CampoA-Crear-Adm-Usuario input{
    width: 208px;
    height: 29px;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0px 19px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Campo2-Crear-Adm-Usuario input{
    width: 208px;
    height: 32px;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0px 19px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Fecha-Input{
    width: 208px;
    height: 29px;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0px 19px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Btn-Crear-Adm-Usuario{
    /* width: 66px; */
    height: 24px;
    background: #3646C4;
    border: 1px solid #3646C3;
    box-sizing: border-box;
    border-radius: 14px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    justify-content: center;
    cursor: pointer;
}

.Posicion-Btn-Crear-Adm-Usuario {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    margin-bottom: 25px;
    padding-right: 51px;
}

.Contenedor-Estado-Adm-Usuario{
    width: 208px;
    height: 32px;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
}

.Switch-Adm .ant-switch{
    background-color: #E5E5E5
}
.Switch-Adm .ant-switch-checked{
    background-color: #E5E5E5
}

.Switch-Adm .ant-switch .ant-switch-handle::before {
    background-color: #FF3742;  
}

.Switch-Adm .ant-switch-checked .ant-switch-handle::before{
    background-color: #1BF785;
}

.Select-Adm-Usuario{
    width: 208px;
    height: 32px;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding-left: 19px;
    padding-right: 5px;
    align-items: center;
}

.Select-Pais-Adm-Usuario{
    width: 208px;
    height: 32px;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding-right: 5px;
    align-items: center;
}

.Contenedor-Opciones-Select-Adm-Usuario{
    width: 208px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 8px 8px;
    position: absolute;
    z-index: 1;
}

.Contenedor-Opciones-Select-Tipos-Usuarios-Adm-Usuario{
    width: 208px;
    height: 110px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 8px 8px;
    position: absolute;
    z-index: 1;
    overflow-y: scroll;
    overflow-x: hidden;
}

.Contenedor-Opciones-Select-Adm-Usuario-Oculto{
    display: none;
}

.Contenedor-Opciones-Select-Tipos-Usuarios-Adm-Usuario-Oculto{
    display: none;
}

.Opciones-Select-Adm-Usuario{
    width: 208px;
    height: 27px;
    background: #FFFFFF;
    cursor: pointer;
    padding-left: 23px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Contenedor-Opciones-Select-Adm-Usuario .Opciones-Select-Adm-Usuario:last-child{
    border-radius: 0px 0px 8px 8px;
}

.Opciones-Select-Adm-Usuario:hover{
    background: #EDF0FA;
    text-decoration-line: underline;
}

.Buscar-Tabla-Adm-Usuario{
    height: 29px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    padding: 0px 24px 0px 5px;
    margin: 10px 0px;
}

.Contenedor-Busqueda-Adm-Usuario{
    position: absolute;
    width: 208px;
    height: 214px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 8px 8px;
    margin-top: 16px;
    overflow: scroll;
    overflow-x: hidden;
}

.Contenedor-Busqueda-Adm-Usuario-Ocultar{
    display: none;
}

.Opcion-Busqueda-Adm-Usuario{
    width: 208px;
    height: 27px;
    background: #FFFFFF;
    cursor: pointer;
    text-align: start;
    padding-left: 12px;
}

.Opcion-Busqueda-Adm-Usuario:hover{
    background: #E6F1FC;
}

.Txt-Opcion-Busqueda-Adm-Usuario{
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    margin-left: 7px;
}

.Cabecera-Buscar-Adm-Usuario{
    position: sticky;
    top: 0;
    z-index: 1;
    background: #FFFFFF;
    padding: 0px 12px;
}

.Cabecera-Buscar-Adm-Usuario .ant-input-affix-wrapper{
    border: 1px solid #1E1E1E;
    border-radius: 8px;
}

.Checkbox-Opcion-Adm-Usuario .ant-checkbox-inner{
    border: 1px solid #558CFF;
}

.Btn-Opcion-Busqueda-Adm-Usuario{
    width: 66px;
    height: 24px;
    border: 1px solid #1E1E1E;
    box-sizing: border-box;
    border-radius: 14px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    justify-content: center;
    cursor: pointer;
}

.Footer-Buscar-Adm-Usuario{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    position: sticky;
    bottom: 0;
    z-index: 1;
    background: #FFFFFF;
}

.Contenedor-Nombre-Img{
    display: flex;
    justify-content: space-between;
    width: 90px;
    align-items: center;
}

.Contenedor-PreImagen-Pais-Seleccionado{
    width: 86px;
    height: 22px;
    background: #EDF0FA;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px 5px;
    margin: 0px 2px;
}

.Espaciado-Preseleccionar-Pais{
    margin-left: 86%;
}

.Contenedor-Cantidad-PreImagen-Pais-Seleccionado{
    width: 50px;
    height: 22px;
    background: #EDF0FA;
    border-radius: 20px;
    padding: 0px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Caja-Modal-Zona .ant-modal-content{
    border-radius: 8px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 738px;
}

.Titulo-Modal-Zona{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    margin-bottom: 10px;
}

.Opciones-Modal-Zona{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    margin-bottom: 5px;
}

.Contenedor-Botones-Modal-Zona{
    display: flex;
    margin-top: 35px;
    justify-content: flex-end;
    padding-right: 20px;
    margin-bottom: 18px;
}

.CampoA-Crear-Adm-Usuario .ant-form-item-control-input-content{
    height: 32px !important;
}

.CampoA-Crear-Adm-Usuario .ant-form-item{
    margin-bottom: 0px;
}

.CampoA-Crear-Adm-Usuario .ant-input:hover{
    border-color: #C4C4C4;
}

.CampoA-Crear-Adm-Usuario .ant-input:focus{
    border-color: #d9d9d9 !important;
}

input[type="date"]:focus { outline: 0; }

.Banderas-lista{
    width: 28px;
}

.Banderas-Lista-Hover{
    display: none;
    padding: 5px;
    position: absolute;
    background: #FFFFFF;
    border-radius: 7px;
    margin-top: -27px;
    box-shadow: 0px 4px 4px #E5E5E5;
    right: 70px;
}

.Lista-Banderas img:hover ~ .Banderas-Lista-Hover{
    display: block;
}

.Text-Nombre-Completo{
    width: 152px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.Txt-PreSeleccion{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #C4C4C4;
}

.Busqueda-Usuarios{
    width: 100%;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 27px !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #A4A3A3;
    padding: 0px 14px;
    border-color: #000000 !important;
}

.Busqueda-Usuarios:hover{
    border-color: #000000 !important;
}

.Input-Buscar-Usuarios .ant-input-affix-wrapper:focus{
    box-shadow: none !important;
    border-color: #000000 !important;
}

.Input-Buscar-Usuarios .ant-input-affix-wrapper-focused{
    box-shadow: none !important;
    border-color: #000000 !important;
}

@media screen and (max-width: 1437px) {
    .Input-Buscar-Usuarios{
        width: 40% !important;
    }
}

.Tabla-Adm-Usuarios tbody tr:hover .Td-Icono-Rebate{
    display: flex;
}

.Tabla-Adm-Usuarios tbody tr:hover .Texto-Indice-Tabla-Usuarios{
    display: none;
}