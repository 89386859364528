.Wbold-S14-H19-C1E1E1E{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #1E1E1E;
}

.Wbold-S14-H19-L0015-C1E1E1E{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #1E1E1E;
    letter-spacing: -0.015em;
}

.Wbold-S14-H19-L0015-CFFFFFF{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    letter-spacing: -0.015em;
}

.Wbold-S13-H17-C1E1E1E{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 17px;
    color: #1E1E1E;
}

.Wbold-S14-H19-CFF3742{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #FF3742;
}

.W600-S12-H16-CFF3742{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #FF3742;
}

.W600-S12-H16-CC4C4C4{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #C4C4C4;
}

.W600-S14-H19-C1E1E1E{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #1E1E1E;
}

.W600-S14-H19-C1E1E1E-L0015{
    font-family: Segoe UI !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    color: #1E1E1E !important;
    letter-spacing: -0.015em !important;
}

.W600-S14-H19-CFEDD34-L0015{
    font-family: Segoe UI !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    color: #FEDD34 !important;
    letter-spacing: -0.015em !important;
}

.W600-S14-H19-CFFFFFF-L0015{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    letter-spacing: -0.015em;
}

.W600-S14-H19-C1876F2-L0015{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #1876F2;
    letter-spacing: -0.015em;
}

.W600-S14-H19-CFFFFFF{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
}

.W600-S12-H19-C1E1E1E{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    color: #1E1E1E;
}

.W600-S12-H16-CFFFFFF{
    font-family: Segoe UI !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #FFFFFF !important;
}

.W600-S12-H16-C1E1E1E-L0015{
    font-family: Segoe UI !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #1E1E1E;
    letter-spacing: -0.015em;
}

.W600-S12-H16-C1E1E1E{
    font-family: Segoe UI !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #1E1E1E;
}

.W600-S12-H16-C1EC0ED-L0015-underline{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    text-decoration-line: underline;
    color: #1EC0ED;
}

.W600-S12-H16-C1E1E1E:hover{
    font-family: Segoe UI !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #1E1E1E;
}

.Wnormal-S14-H19-C1E1E1E{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #1E1E1E;
}


.Wnormal-S14-H19-CFF3742{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #FF3742;
}

.Wnormal-S14-H19-L0015-C1E1E1E{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #1E1E1E;
    letter-spacing: -0.015em;
}

.Wnormal-S12-H16-C004FB8{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #004FB8;
}

.Wbold-S12-H16-C004FB8{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #004FB8;
}

.Wbold-S12-H16-C1E1E1E{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #1E1E1E;
}

.Wbold-S14-H16-C1E1E1E{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #1E1E1E;
}

.Wbold-S12-H16-CFFFFFF{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
}

.Wbold-S12-H16-CFFFFFF:hover{
    font-family: Segoe UI !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #FFFFFF !important;
}

.W600-S11-H15-C1E1E1E{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #1E1E1E;
}

.W600-S11-H15-CFFFFFF{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #FFFFFF;
}

.Wbold-S14-H19-CFFFF00{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #FFFF00;
}

.Wbold-S14-H19-CFFFFFF{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
}

.Wbold-S20-H35-C1E1E1E{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 35px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Wbold-S26-H35-C1E1E1E{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 35px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Wbold-S20-H35-C3646C4{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 35px;
    letter-spacing: -0.015em;
    color: #3646C4;
}

.Wbold-S26-H35-C004FB8{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 35px;
    letter-spacing: -0.015em;
    color: #004FB8;
}

.Wbold-S26-H35-CFFFFFF{
    font-family: Segoe UI !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 26px !important;
    line-height: 35px !important;
    letter-spacing: -0.015em !important;
    color: #FFFFFF !important;
}

.Wbold-S18-H24-CFFFFFF{
    font-family: Segoe UI !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 18px !important;
    line-height: 24px !important;
    letter-spacing: -0.015em !important;
    color: #FFFFFF !important;
}

.Wnormal-S16-H21-CFFFFFF{
    font-family: Segoe UI !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 21px !important;
    color: #FFFFFF !important;
}

.Wnormal-S14-H16-C1E1E1E{
    font-family: Segoe UI !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #1E1E1E !important;
}

.Wbold-S16-H21-CFFFFFF{
    font-family: Segoe UI !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 21px !important;
    color: #FFFFFF !important;
}

.Wbold-S14-H19{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
}

.Wbold-S11-H15-O07{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    opacity: 0.7;
}

.Wbold-S10-H13-CFFBB37{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 13px;
    color: #FFBB37;
}

.Wbold-S16-H21-C1E1E1E{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.W600-S14-H19-L0015-C1E1E1E{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.W600-S14-H19-C558CFF-L0015{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #558CFF;
}

.Wbold-S14-L0015-CFFBB37{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #FFBB37;
}

.Wbold-S14-L0015-C1E1E1E{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.W600-S14-H19-L0015-CFFBB37{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #FFBB37;
}


.W600-S14-H19-L0015-CFFFFFF{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

.Wnormal-S12-H16-C1EC0ED{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #1EC0ED;
}

.Wnormal-S12-H16-C1876F2{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #1876F2;
}

.Wnormal-S12-H16-C000000-L0015{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    letter-spacing: -0.015em;
}

.Wbold-S20-H27-CFFFFFF-L0015{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

.Wbold-S27-H36-C000000{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 36px;
    color: #000000;
}

.W600-S14-H19-CC4C4C4-L0015{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #C4C4C4;
}

.Wnormal-S11-H15-C706C64-L0015{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: -0.015em;
    color: #706C64;
}

.Wnormal-S11-H15-C1E1E1E{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    color: #1E1E1E;
}

.Wbold-S15-H24-L0015-C000000{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.015em;
    color: #000000;
}

.Wnormal-S15-H24-L0015-C00008{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.015em;
    color: rgba(0, 0, 0, 0.8);
}

.Wbold-S40-H53-C3646C4{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 53px;
    color: #3646C4;
}

.Wbold-S9-H12-CE41A37{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 12px;
    color: #E41A37;
}

.W600-S12-H16-CE41A37{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #E41A37;
}


.W600-S14-H19-CA4A3A3-L0015{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #A4A3A3;  
}

.W700-S26-H35-C1E1E1E-L0015{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.W700-S14-H19-CFFFFFF-L0015{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

.W700-S16-H21-CFFFFFF-L0015{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

.W700-S16-H21-C1E1E1E{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #1E1E1E;
}

.W400-S12-H16-C000000-L0015{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #000000;
}

.W400-S12-H16-C1E1E1E-L0015{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.W400-S11-H15-CA4A3A3-L0015-SYItalic{
    font-family: 'Segoe UI';
    font-style: italic;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: -0.015em;
    color: #A4A3A3;
}

.W700-S20-H27-C1E1E1E-L0015{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.W700-S20-H27-CFFFFFF-L0015{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

.W700-S10-H13-CFFFFFF-L0015{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

.W600-S10-H13-C1E1E1E-L0015{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.W900-S17-H20-CFFFFFF{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 900;
    font-size: 17px;
    line-height: 20px;
    color: #FFFFFF;
}

.W400-S14-H16-C1E1E1E{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #1E1E1E;
}

.W400-S14-H19-C1E1E1E{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.W500-S17-H20-CFFFFFF{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #FFFFFF;
}

.W700-S15-H20-C1E1E1E{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #1E1E1E;
}

.W700-S25-H33-CFFFFFF-L0015{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 33px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

.W400-S16-H21-CFFFFFF{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
}

.W700-S23-H23-CFFFFFF{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
}

.W700-S12-H16-CFFFFFF-L0015{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

.W600-S12-H16-C00CA8A{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #00CA8A;
}
