#Login-Contenedor-Formulario{
    width: 100%;
    height: 100vh;
    position: absolute;
    background: white;
    display: flex;
    place-content: center;
    flex-direction: column;
    align-items: center;
}

#Login-Formulario{
    margin:0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    place-content: center;
    position: absolute;
    /* background-color: rgb(220, 202, 202); */
    width: 40%;
    height: 100vh;
    margin-top: -40px;

}

#Login-Formulario-Input{
    width: 299px;
    height: 47px;
    background: #FFFFFF;
    padding-left: 10px;
    font-family: Segoe UI;
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    border-radius: 8px;
}

#Login-Formulario-Input-Contrasenia{
    width: 299px;
    height: 38px;
    background: #FFFFFF;
    padding-left: 10px;
    font-family: Segoe UI;
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    border-radius: 8px;
}

#Login-Formulario-Texto{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #1E1E1E;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -13px;
    text-decoration: underline;
}

#Login-Formulario-Texto:hover{
    /* font-weight: bold; */
}












#Login-Formulario-Boton{
    width: 299px;
    height: 47px;
    background: #3946AB;
    display: flex;
    align-items: center;
    place-content: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: white;
    border-radius: 20px;
    border: none;
    outline: none;
}
#Login-Formulario-Boton:hover{
    background: #558CFF;
}

#Login-Formulario-Boton-Desactivado{
    width: 299px;
    height: 47px;
    background: #000000;
    display: flex;
    align-items: center;
    place-content: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #FFFF00;
    border-radius: 20px;
    border: none;
    outline: none;
}

#Btn-Solicitud-Enviada{
    width: 299px;
    height: 47px;
    background: #3946AB;
    display: flex;
    align-items: center;
    place-content: center;
    color: #FFFF00;
    border-radius: 20px;
    border: none;
    outline: none;
}

#Icono{
    width: 90px;
    height: 82px;
    margin-bottom: 15px;
}

#Banner-Icono{
    width: 11%;
}

.Banner-Icono-Grow-Text{
    position: absolute;
    left: 0;
    width: 200px;
}

.Banner-Icono-Grow-Lion{
    position: absolute;
    left: 0;
    width: 200px;
}

#Login-Formulario-Titulo{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    color: #1E1E1E;
    margin-bottom: 20px;
}

#Login-Formulario-Titulo-Solicitud{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #1E1E1E;
    margin-top: 10px;
}

#Solicitud-Informacion{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #A4A3A3;
    width: 465px;
}

.seleccionar .ant-select-selector{
    border-radius: 8px !important;
    height: 46px !important;
    padding: 3px 11px !important;
}

.seleccionar .ant-select-arrow{
    color: #1E1E1E;
}

.Login-Banner{
    position: absolute;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
}

.banner{
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
}

.banner p{
    display: inline-block;
    padding-left: 100%;
    animation: banner 30s linear infinite;
    animation-delay: 0s;
}

.banner2 p {
    animation-delay: 15s;
}

@keyframes banner { 
    100% {
        visibility: hidden;
        transform: translate(-100%, 0);
    }
    /* to {
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-100%);
    } */
}

.banner0{
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
}
/* 
.banner0 p{
} */

.banner0 p{
    display: inline-block;
    padding-left: 12%;
    animation: banner0 14.5s linear ;
    animation-delay: 0s;
    margin-left: 50px;
}

@keyframes banner0 { 
   100% { 
        visibility: hidden;
        transform: translate(-100%, 0); 
    }
}

.ocultar{
    display: none;
}

.ant-form-item-control-input-content {
    height: 47px !important;
}

@media screen and (max-width: 425px) {
    #Login-Formulario{
        width: 100%;
    }
    #Banner-Icono{
        width: 21%;
    }
}

@media screen and (max-width: 1024px) {
    .Login-Banner{
        display: none;
    }
}

@media screen and (min-width: 425px) and (max-width: 768px) {
    #Login-Formulario{
        width: 80%;
    }
}

.Login-Banner-Letras-SinRelleno{
    -webkit-text-stroke: 0.7px #3946AB;
    color: transparent; 
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.105em;
    margin: 0px 23px;
}

.Login-Banner-Letras-ConRelleno{
    font-style: normal;
    font-family: Segoe UI;
    font-weight: 900;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.105em;
    color: #3946AB;
    margin: 0px 23px;
}

@media screen and (min-width: 1500px) and (max-width: 1600px){
    .Login-Banner-Letras-ConRelleno{
        margin: 0px 35px;
    }
    .Login-Banner-Letras-SinRelleno{
        margin: 0px 35px;
    }
    .banner0 p{
        margin-left: 5%;
    }
}

@media screen and (min-width: 1601px) and (max-width: 1700px){
    .Login-Banner-Letras-ConRelleno{
        margin: 0px 42px;
    }
    .Login-Banner-Letras-SinRelleno{
        margin: 0px 42px;
    }
    .banner0 p{
        margin-left: 5%;
    }
}

@media screen and (min-width: 1701px) and (max-width: 1800px){
    .Login-Banner-Letras-ConRelleno{
        margin: 0px 52px;
    }
    .Login-Banner-Letras-SinRelleno{
        margin: 0px 52px;
    }
    .banner0 p{
        margin-left: 5%;
    }
}

@media screen and (min-width: 1801px) and (max-width: 1900px){
    .Login-Banner-Letras-ConRelleno{
        margin: 0px 57px;
    }
    .Login-Banner-Letras-SinRelleno{
        margin: 0px 57px;
    }
    .banner0 p{
        margin-left: 5%;
    }
}

@media screen and (min-width: 1901px) and (max-width: 2000px){
    .Login-Banner-Letras-ConRelleno{
        margin: 0px 67px;
    }
    .Login-Banner-Letras-SinRelleno{
        margin: 0px 67px;
    }
    .banner0 p{
        margin-left: 5%;
    }
}

@media screen and (min-width: 2001px) and (max-width: 2200px){
    .Login-Banner-Letras-ConRelleno{
        margin: 0px 72px;
    }
    .Login-Banner-Letras-SinRelleno{
        margin: 0px 72px;
    }
    .banner0 p{
        margin-left: 5%;
    }
}

#Login-Formulario-Subtitulo{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #1E1E1E;
}

/* .ant-form-item-explain-error{
    margin-top: 10px !important;
} */

/* .seleccionar {
    font-weight: bold;
} */
/* ant-select-item ant-select-item-option ant-select-item-option-active
.ant-select-item ant-select-item-option:hover{
    background: #FFFF00 !important;
} */
.ant-select-item-option-active{
    /* background: #FFFF00 !important; */
    background: #EDF0FA !important;
}
.ant-select-item-option-selected{
    /* background: #ffff0085 !important; */
    background: #EDF0FA !important;
}
.ant-select-item-option{
    font-family: Segoe UI !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 21px !important;
    color: #1E1E1E !important;
}

#Banner-Fondo{
    background: #3646C4;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    place-content: center;
}

#Banner{
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    place-content: center;
}

#Banner-Animacion{
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    place-content: center;
    animation-name: PreloadAnimacion;
    animation-delay: 0s;
    animation-duration: 3s;
    animation-fill-mode: forwards;
}

@keyframes PreloadAnimacion{
    from{ top   : 0; }
    to { top   : -100%; }
}


.Banner-Icono-Grow-Text{
    animation-name: rotacion;
	animation-duration: 18s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes rotacion {
    0% {
      transform:rotate(0deg);
    }
    100% {
      transform:rotate(360deg);
    }
}

.Btn-Wsp-Login{
    width: 70px;
    height: 70px;
    border-radius: 50px;
    background-color: #1BD741;
    position: absolute;
    bottom: 80px;
    right: 40px;
    cursor: pointer;
}

.Icono-Btn-Wsp-Login{
    width: 50px;
    position: absolute;
    bottom: 10px;
    left: 11px;
}