.Fila-Btns-Lista-Precios{
    display: flex;
    margin-left: 40px;
    margin-bottom: 20px;
}

.Btn-Lista-Precios{
    border: 1px solid #1E1E1E !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    margin-right: 15px !important;
    padding-top: 4px !important;
    padding-bottom: 3px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    cursor: pointer !important;
    white-space: nowrap !important;
}

.Btn-Lista-Precios:hover{
    border: 1px solid #3646C3 !important;
    background-color: #3646C3 !important;
    color: white !important;
}

.Btn-Lista-Precios:focus{
    color: white !important;
}

.Btn-Lista-Precios-Seleccionado:focus{
    color: white !important;
    border: 1px solid #1E1E1E !important;
}

.Btn-Lista-Precios-Seleccionado:hover{
    color: white !important;
}


.Btn-Lista-Precios-Seleccionado{
    border: 1px solid #3646C3 !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    margin-right: 15px !important;
    padding-top: 4px !important;
    padding-bottom: 3px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    cursor: pointer !important;
    white-space: nowrap !important;
    background-color: #3646C3 !important;
}

.Btn-Todos-Filtros-Lista-Precios{
    border: 1px solid #1E1E1E !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    margin-right: 15px !important;
    padding-top: 6px !important;
    padding-bottom: 5px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    cursor: pointer !important;
    white-space: nowrap !important;
}

.Btn-Descargar-Lista-Precios{
    /* background: #3646C3 !important; */
    border-radius: 8px !important;
    width: 102px !important;
    height: 36px !important;
    cursor: pointer !important;
    float: right !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-right: 40px !important;
    /* border: 1px solid #3646C3 !important; */

    /*  */

    background: #558CFF !important;
    color: white !important;
    border: 1px solid #558CFF !important
}

.Btn-Descargar-Lista-Precios:focus{
    color: white !important;
}

.Btn-Descargar-Lista-Precios:hover{
    background: #3646C3 !important;
    border: 1px solid #3646C3 !important;
    color: white !important;
}

.Btn-Descargar-Lista-Precios-Desactivado{
    border: 1px solid #1E1E1E !important;
    border-radius: 8px !important;
    width: 102px !important;
    height: 36px !important;
    cursor: not-allowed !important;
    float: right !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-right: 40px !important;

    /*  */
    background: #558CFF !important;
    color: white !important;
    border: 1px solid #558CFF !important;
}


.Btn-Descargar-Lista-Precios-Desactivado:hover{
    background: #3646C3 !important;
    border: 1px solid #3646C3 !important;
}

#Contenedor-Tabla-Lista-Precios{
    box-shadow: 0px 0px 15px #D8DFE9;
    height: 500px;
    background-color: white;
    position: relative;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 80px;
    overflow: auto;
    border-radius: 8px;
}

/*  */

.Tabla-Principal{
    overflow:scroll;
    white-space: nowrap;
    position: relative;
    z-index: 0;
}

.Tabla-Principal thead th {
    background: #1EC0ED;
    padding-top: 0px;
    padding-bottom: 0px;
    z-index: 2;
    text-align: -webkit-center;
    padding-left: 5px;
    padding-right: 5px;
}

.Tabla-Principal thead{
    border-top: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;   
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */
}

.Tabla-Principal tbody tr td{
    text-align: -webkit-center;
    padding-top: 5px;
    padding-bottom: 5px;
}

/* STATIC */
.Tabla-Principal thead th:first-child {
    /* position: sticky;
    left: 0;
    z-index: 2;
    background-color: #1EC0ED; */
}

.Tabla-Principal tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 0;
    background-color: white;
    top: 48px;
}

/* FIN STATIC */

.Contenedor-Btn-Filtro-Lista-Precios{
    position: relative;
    height: 100%;
}

.Btn-Filtro-Lista-Precio{
    border: 1px solid #1E1E1E;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding-left: 14px;
    padding-right: 35px;
    position: relative;

    margin-right: 20px;
    cursor: pointer;
    height: 36px;
}

.Icono-Flecha-Abajo-Negrita-Lp{
    position: absolute;
    width: 20px;
    right: 5px;
    top: 8px;
}

.Contenedor-Filtro-Lp{

    position: absolute;
    width: 100%;
    height: 180px;
    background: #FFFFFF;
    /* background: black; */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 8px 8px;
    top: 36px;
    left: 0;
    z-index: 1;

    padding-left: 10px;
    padding-right: 10px;

}

.Titulo-Filtro-Lp{

    margin-top: 7px;
    margin-bottom: 5px;

}

.Cuerpo-Filtro-Lp{
    width: 100%;
    height: 115px;
    /* background-color: red; */
    overflow-y: auto;
}

.Btns-Filtro-Lp{
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 6px;
    /* margin-top: 10px; */
}

.Btn-Aceptar-Filtro-Lp{
    background: #3646C3;
    border: 1px solid #3646C3;
    box-sizing: border-box;
    border-radius: 14px;
    width: 52.99px;
    height: 19.27px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    cursor: pointer;
}

.Btn-Cancelar-Filtro-Lp{
    border: 1px solid #1E1E1E;
    box-sizing: border-box;
    border-radius: 14px;
    width: 52.99px;
    height: 19.27px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    cursor: pointer;
}

.Check-Filtro-Lp .ant-checkbox{
    width: 12px;
}

.btn-filtrar-Verde-listaprecios{
    width: 43px;
    height: 36px;
    border: 1px solid #00CA8A;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    
    display: flex;
    place-items: center;
    justify-content: center;
}

.btn-filtrar-Verde-listaprecios:hover{
    background: #00CA8A;
}

.btn-filtrar-Verde-listaprecios:hover .Icono-Duplicados-Complejos-Rojo-Lista-Precios{
    display: none;
}

.btn-filtrar-Verde-listaprecios:hover .Icono-Duplicados-Complejos-Blanco-Lista-Precios{
    display: flex;
}



.btn-filtrar-duplicados-complejos-listaprecios{
    width: 43px;
    height: 36px;
    border: 1px solid #E41A37;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    
    display: flex;
    place-items: center;
    justify-content: center;
}

.btn-filtrar-duplicados-complejos-listaprecios:hover{
    background: #E41A37;
}

.btn-filtrar-duplicados-complejos-listaprecios:hover .Icono-Duplicados-Complejos-Rojo-Lista-Precios{
    display: none;
}

.btn-filtrar-duplicados-complejos-listaprecios:hover .Icono-Duplicados-Complejos-Blanco-Lista-Precios{
    display: flex;
}

.Icono-Duplicados-Complejos-Rojo-Lista-Precios{
    width: 22px;
}

.Icono-Duplicados-Complejos-Blanco-Lista-Precios{
    display: none;
    width: 22px;
}

.btn-filtrar-duplicados-complejos-seleccionado-listaprecios{
    width: 43px;
    height: 36px;
    border: 1px solid #E41A37;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    
    display: flex;
    place-items: center;
    justify-content: center;
}

.btn-filtrar-verde-seleccionado-listaprecios{
    width: 43px;
    height: 36px;
    border: 1px solid #00CA8A;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    
    display: flex;
    place-items: center;
    justify-content: center;
}