.Contenedor-Administrativo{
    margin-top: 100px;
    margin-bottom: 50px;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
}

.Titulo-Administrativo{
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    padding-left: 40px;
    padding-top: 19px;
}

.Btn-Seleccionado-Administrativo{
    height: 36px;
    background: #3646C4;
    border-radius: 8px;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.Btn-NoSeleccionado-Administrativo{
    height: 36px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #1E1E1E;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #1E1E1E;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.Caja-Botones-Administrativo{
    display: flex;
    width: 100%;
    padding-left: 40px;
    padding-top: 11px;
}

.Caja-Botones-Administrativo .Btn-Seleccionado-Administrativo:nth-child(1){
    margin-left: 16px;
}

.Caja-Botones-Administrativo .Btn-NoSeleccionado-Administrativo:nth-child(1){
    margin-left: 16px;
}

.Caja-Botones-Administrativo .Btn-Seleccionado-Administrativo:nth-child(2){
    margin-left: 16px;
}

.Caja-Botones-Administrativo .Btn-NoSeleccionado-Administrativo:nth-child(2){
    margin-left: 16px;
}

.Caja-Botones-Administrativo .Btn-Seleccionado-Administrativo:nth-child(3){
    margin-left: 18px;
}

.Caja-Botones-Administrativo .Btn-NoSeleccionado-Administrativo:nth-child(3){
    margin-left: 18px;
}

.Contenedor-Busqueda-Administrativo{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 58px;
    padding-top: 11px;
}

.flip-card {
    background-color: transparent;
    width: 192px;
    height: 183px;
    perspective: 1000px;
}
  
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 8px;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    width: 192px;
    height: 183px;
    background: #FFFFFF;
    box-shadow: 0px 4px 7px #C4C4C4;
    border-radius: 8px;
}

.flip-card-back {
    width: 192px;
    height: 183px;
    background: #558CFF;
    box-shadow: 0px 4px 7px #C4C4C4;
    border-radius: 8px;
    transform: rotateY(180deg);
}

.Imagen-Perfil{
    margin-top: 19px;
    margin-bottom: 10px;
    width: 99px;
    height: 99px;
}

.Texto-Card-Perfil{
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;    
    text-align: center;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Texto2-Card-Perfil{
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.015em;    
    color: #558CFF;
    margin-top: 3px;
}

.Texto3-Card-Perfil{
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

.Imagen2-Perfil{
    width: 58px;
    height: 58px;
    margin-top: 44px;
    margin-bottom: 10px;
    cursor: pointer;
}

.cardCrearTipoUsuario{
    background: #558CFF;
    width: 192px;
    height: 183px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 7px #c4c4c4;
    border-radius: 8px;
    padding-bottom: 30px;
}

.cardTipoUsuario{
    background: #FFFFFF;
    width: 192px;
    height: 183px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 7px #c4c4c4;
    border-radius: 8px;
}

.Busqueda-Administrativo{
    width: 529px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 27px !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #A4A3A3;
    padding: 0px 14px;
    border-color: #000000 !important;
}

.Busqueda-Administrativo:hover{
    border-color: #000000 !important;
}

.Contenedor-Busqueda-Administrativo .ant-input-affix-wrapper:focus{
    box-shadow: none !important;
    border-color: #000000 !important;
}

.Contenedor-Busqueda-Administrativo .ant-input-affix-wrapper-focused{
    box-shadow: none !important;
    border-color: #000000 !important;
}