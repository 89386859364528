.Btn-Descargar-Data-Rebate{
    width: 84px;
    height: 36px;
    background: #3646C3;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 34px;
}

.Btn-Descargar-Data-Rebate:hover{
    background: #558CFF;
}

.Btn-Select-Grupo-Rebate{
    position: relative;
    /* border: 1px solid #1E1E1E; */
    box-sizing: border-box;
    /* border-radius: 8px; */
    display: flex;
    align-items: center;
    width: 160px;
}

.Btn-Select-Grupo-Rebate .ant-select-selector{
    border: 1px solid #1E1E1E !important;
    border-radius: 8px !important;
    height: 36px !important;
}

.Icono-Flecha-Abajo-Select-Rebate{
    position: absolute;
    right: 10px;
    width: 20px;
    /* top: 0; */
}

.Btn-Select-Grupo-Rebate .ant-select-selection-placeholder{
    font-family: Segoe UI !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    color: #1E1E1E !important;
    letter-spacing: -0.015em !important;

    display: flex !important;
    padding-top: 5px !important;
}

.Btn-Select-Grupo-Rebate .ant-select-selection-item{
    font-family: Segoe UI !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    color: #1E1E1E !important;
    letter-spacing: -0.015em !important;

    display: flex !important;
    padding-top: 5px !important;
}


.Titulo-Btn-Select-Grupo-Rebate{
    padding-left: 40px;
    padding-right: 40px;
}

.Contenedor-Fila-Filtros-Rebate{
    /* background-color: red; */
    width: 100%;
    display: flex;
    padding-left: 40px;
    position: relative;
}

.Contenedor-Filtro-Fecha-Seleccionado-Rebate{
    width: 80%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.Filtro-Fecha-Seleccionado-Rebate{
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 8px;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}



.Btn-Agregar-Filas-Rebate{
    width: 21px;
    height: 21px;
    
    border-radius: 50px;
    position: absolute;
    left: -21px;
    top: -11px;
    border: 1px solid #1EC0ED;
    background-color: white;
    cursor: pointer;
}

.Btn-Flecha-Retroceder-Rebate{
    top: 130px;
    position: absolute;
    width: 48px;
    height: 48px;
    background: #558CFF;
    border-radius: 50px;
    left: 70px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: white
}

.Btn-Flecha-Avanzar-Rebate{
    top: 130px;
    position: absolute;
    width: 48px;
    height: 48px;
    background: #558CFF;
    border-radius: 50px;
    right: 70px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: white
}

.Select-Editar-Rebate .ant-select-selector{
    border: none !important;
    box-shadow: none !important;
    /* border-bottom: 1px solid #1E1E1E !important; */
    color: #C4C4C4 !important;
}
.Select-Editar-Rebate .ant-select-selector:focus{
    border: none !important;
    box-shadow: none !important;
}

/* TABLA ----------> */
.Contenedor-Completo-Tabla-Rebate{
    width: 100%;
    display: flex;
    justify-content: center;
    /* background-color: red; */
    position: relative;
    align-items: center;
}

.Contenedor-Tabla-Rebate{
    /* margin-top: 30px; */

    /* box-shadow: 0px 0px 15px #D8DFE9; */
    height: 300px;
    /* background-color: red; */
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    border-radius: 8px;
    width: 80%;
    padding-left: 20px;

}

.Tabla-Principal-Rebate{
    overflow:scroll;
    white-space: nowrap;
    /* position: relative; */
    z-index: 0;

    position: absolute;
    top: 0;
    /* height: 500px; */
    width: 97%;
    left: -1300px;
    margin-right: 20px;
}

.Tabla-Principal-Rebate thead th {
    background: #1EC0ED;
    padding-top: 15px;
    padding-bottom: 15px;
    z-index: 2;
    text-align: -webkit-center;
}

.Tabla-Principal-Rebate thead{  
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */
    z-index: 1;
}

.Fila-Agregar-Nuevo-Rebate td{
    text-align: -webkit-center;
    
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    border-right: 1px solid #E5E5E5;
    padding-top: 15px;
    padding-bottom: 15px;

    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px #E5E5E5;
    color: transparent;
}


.Tabla-Principal-Rebate tbody tr td{
    text-align: -webkit-center;
    /* padding-top: 3px; */
    /* padding-bottom: 3px; */
    border-bottom: 1px solid #E5E5E5;
    border-left: 1px solid #E5E5E5;
    padding-top: 5px;
    padding-bottom: 5px;
}


.Tabla-Principal-Rebate tbody tr td .ant-input-number{
    border: none;
    width: 80px;
}

.Tabla-Principal-Rebate tbody tr td .ant-input-number-focused{
    border: none;
    box-shadow: none;
    width: 80px;
}

.Tabla-Principal-Rebate tbody tr td .ant-input-number .ant-input-number-handler-wrap{
    display: none;
}

.Tabla-Principal-Rebate tbody tr td .ant-input-number-focused .ant-input-number-handler-wrap{
    display: none;
}

.Tabla-Principal-Rebate tbody tr{
    animation-delay: 3s;
}

.Tabla-Principal-Rebate tbody tr:hover{
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px #E5E5E5;
}

.Td-Icono-Rebate{
    display: none;
    place-content: center;
}

.Tabla-Principal-Rebate tbody tr:hover .Td-Normal-Porcentaje-Rebate{
    display: none;
}

.Tabla-Principal-Rebate tbody tr:hover .Td-Icono-Rebate{
    display: flex;
}

.Tabla-Principal-Rebate tbody tr:hover .Contenedor-Img-Nuevo-Banco-Imagenes{
    display: none;
}

.Tabla-Principal-Rebate tbody tr:hover .Contenedor-Icons-Nuevo-Banco-Imagenes{
    display: flex;
}


.Input-Editar-Porcentaje-Rebate input{
    outline: none !important;
    /* border-bottom: 1px solid #1E1E1E; */
    width: 80px;
    color: #C4C4C4 !important;
}


/* ANIMACIONES PARA EL CAROUSEL */
.Tabla-Principal-Rebate-Ocultando{
    animation-duration: 1s;
    position: absolute;
    left: -1300px;
    /* background: black; */
    animation-name: animacion-tabla-ocultar-rebate;
}

@keyframes animacion-tabla-ocultar-rebate {
    from {left: 20px;}
    to {left: -1300px;}
}

.Tabla-Principal-Rebate-Mostrar{
    animation-duration: 1s;
    position: absolute;
    left: 20px;
    animation-name: animacion-tabla-mostrar-rebate;
    /* background-color: red; */
}

@keyframes animacion-tabla-mostrar-rebate {
    from {left: 1300px;}
    to {left: 20px;}
}

.Tabla-Principal-Rebate-Retroceder-Mostrar{
    animation-duration: 1s;
    position: absolute;
    left: 20px;
    animation-name: animacion-tabla-retroceder-mostrar-rebate;
}

@keyframes animacion-tabla-retroceder-mostrar-rebate {
    from {left: -1300px;}
    to {left: 20px;}
}

.Tabla-Principal-Rebate-Retroceder-Ocultar{
    animation-duration: 1s;
    position: absolute;
    left: 1300px;
    animation-name: animacion-tabla-retroceder-ocultar-rebate;
}

@keyframes animacion-tabla-retroceder-ocultar-rebate {
    from {left: 20px;}
    to {left: 1300px;}
}


.Contenedor-Btn-Guardar-Rebate{
    display: flex;
    justify-content: center;
}

.Btn-Guardar-Rebate{
    width: 114px;
    height: 36px;
    background: #3646C3;
    border-radius: 8px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
}

.Icono-Btn-Agregar-Filas-Rebate{
    width: 21px;
    position: absolute;
    top: -1px;
    left: -1px;
}

.Icono-Flecha-Blanca-Rebate{
    width: 13px;
    position: absolute;
    left: 19px;
}


/*  */

.Btn-Guardar-Data-Rebate{
    border: 1px solid #558CFF;
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    height: 35px;
}

.Btn-Guardar-Data-Rebate:hover{

    background-color: #558CFF;
    color: white;

}

.Btn-Editar-Data-Rebate{
    border: 1px solid #558CFF;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    width: 42px;
    position: relative;
}

.Btn-Editar-Data-Rebate:hover{
    background-color: #558CFF;
}

.Btn-Editar-Data-Rebate:hover .Icono-Editar-Blanco-Data-Rebate{
    display: flex;
}

.Btn-Editar-Data-Rebate:hover .Icono-Editar-Data-Rebate{
    display: none;
}


.Icono-Editar-Blanco-Data-Rebate{
    width: 20px;
    position: absolute;
    top: 8px;
    left: 10px;
    display: none;
}

.Icono-Editar-Data-Rebate{
    width: 20px;
    position: absolute;
    top: 8px;
    left: 10px;
}