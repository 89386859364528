.Contenedor-Top{
    width: 100%;
    height: 60px;
    background: #558CFF;
    display: flex;
    align-items: center;
    /* position: relative; */
    position: fixed;
    top: 0;
    z-index: 3;
}

.Logo-KC-Creciendo-Juntos{
    width: 60px;
    margin-right: 20px;
    margin-left: 40px;
    cursor: pointer;
}

.Contenedor-Items-Top{
    margin-left: 10px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 53px;
    padding-right: 34px;
    cursor: pointer;
    border-radius: 27px;
    position: relative;
    /* background: red; */
}

.Contenedor-Items-Top:hover{
    /* background: #3646C3; */
    /* background: red; */
    /* color: white; */
}

.Contenedor-Items-Mi-Cuenta-Top{
    position: absolute;
    right: 40px;
    cursor: pointer;
}

.Contenedor-Fecha-Actualizacion-Top{
    width: 100%;
    height: 34px;
    background: #F1F1F1;
    padding-left: 40px;
    display: flex;
    align-items: center;
    margin-top: 60px;
    margin-bottom: -25px;
    position: relative;
    box-shadow: 0 4px 4px rgb(216 223 233 / 92%);
}

.Cuerpo-Item-Filtro-Top{
    position: absolute;
    width: 144px;
    /* height: 77px; */
    background: #FFFFFF;
    border-radius: 0px 0px 8px 8px;
    top: 48px;
    z-index: 1;
    box-shadow: 0px 3px 8px #C4C4C4;
    padding-top: 5px;
    padding-bottom: 5px;
}

.Fila-Cuerpo-Item-Filtro-Top{
    position: relative;
    padding-left: 30px;
    padding-bottom: 5px;
    padding-top: 5px;
    cursor: pointer;
}

.Fila-Cuerpo-Item-Filtro-Top:hover{
    background: #E4E6EB;
}

.Icono-Fila-Cuerpo-Item-Filtro-Top{
    width: 14px;
    position: absolute;
    left: 11px;
    top: 8px;
}

.Img-Icono-Top{
    width: 30px;
    position: absolute;
    left: 25px;
    top: 4px;
}

.Img-Icon-Usuario{
    width: 25px;
}

.Contenedor-Expandible-Mi-Cuenta{
    width: 240px;
    height: 135px;
    right: 20px;
    top: 60px;

    background: #FFFFFF;
    box-shadow: 0px 6px 17px #E5E5E5;
    border-radius: 0px 0px 8px 8px;

    position: absolute;

    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
}

.Contenedor-Expandible-Primera-Parte-Mi-Cuenta{
    width: 100%;
    height: 66px;
    background: #F1F1F1;
    border-radius: 0px 0px 8px 8px;
    display: flex;
}

.Contenedor-Expandible-Segunda-Parte-Mi-Cuenta{
    height: 45px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding-bottom: 8px;
}

.Contenedor-Cerrar-Sesion-Top{
    border-radius: 100px;
    border: 1px solid black;
    margin-left: 10px;
    margin-right: 10px;
}

.Cerrar-Sesion-Top{
    width: 35px;
}

.Contenedor-Persona-Blanco{
    background-color: black;
    border-radius: 100px;
    width: 57px;
    height: 57px;
    display: flex;
    align-items: center;
    place-content: center;
    margin-top: 5px;
    margin-left: 5px;
}

.Icono-Persona-Blanco-Top{
    width: 50px;
}

.Icono-Actualizar{
    width: 25px;
    position: absolute;
    right: 30px;
    top: 3px;
    cursor: pointer;
}

.Fondo-Notificaciones{
    position: absolute;
    right: 140px;
    cursor: pointer;
}

.Notificacion-Punto{
    background: red;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 8px;
    right: 1px;
}

.Contenedor-Notificaciones-Top{
    width: 233px;
    height: 292px;
    position: absolute;
    right: 50px;
    top: 60px;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 8px 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}

.Contenedor-Imagen-Txt{
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 6px;
    margin: 7px;
    cursor: pointer;
}

.Contenedor-Imagen-Txt:hover{
    background: #F1F1F1;
}
.Contenedor-Txt-Notificaciones{
    margin-left: 9px;
    color: black;
}

.Txt-Tiempo-Notificaciones{
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 9px !important;
    line-height: 12px !important;
    letter-spacing: -0.015em !important;
    color: #558CFF !important;
}

.Punto-Azul-Notificacion{
    width: 7px;
    height: 7px;
    background: #558CFF;
    border-radius: 8px;
}

.Contenedor-SubTitulo-Notificaciones{
    padding-left: 15px;
    padding-top: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: -0.015em;
    color: #1E1E1E;
}