
.Contenedor-Cabecera-Tabla{
    display: flex;
    place-items: center;
    justify-content: center;
}

.Icono-Flecha-Tabla{
    /* width: 40px; */
    width: 1px;
    position: absolute;
    left: -15px;
    top: -14px;
}

.Contenedor-Icono-Flecha-Tabla{
    width: 10px;
    height: 10px;
    /* background: green; */
    margin-top: 2px;
    margin-left: 10px;
    position: relative;
    cursor: pointer;
}

.Contenedor-Filtro-Tabla-LP{
    position: absolute;
    width: 197px;
    height: 224px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 8px 8px;
    top: 57px;
    left: 0;
}

.Tabla-Principal thead{
    z-index: 1;
    /* position: relative; */
}

.Tabla-Principal tbody{
    z-index: 0;
}

.Contenedor-Fila-TablaLp{
    /* position: relative; */
}

.Contenedor-Fila-TablaLp:hover .Contenedor-Opciones-TablaLp{
    display: revert;
    /* position: relative; */
}

.Contenedor-Opciones-TablaLp{
    display: none;
    /* position: relative; */
}

.Contenedor-Fila-TablaLp:hover .Contenedor-Item-TablaLp{
    display: none;
}

.Contenedor-Fila-TablaLp:hover .Contenedor-Categoria-TablaLp{
    display: none;
}

.Input-Editando-TablaLp{
    outline: none;
    border: none;
    width: 95%;
}

.Input-Editando-TablaLp:focus{
    outline: none;
}


.Modal-Eliminar-Lp-TablaLp .ant-modal-content{
    border-radius: 8px;
}