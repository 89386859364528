.contenedorImgHover{
    width: 100%;
    display: flex;
    /* justify-content: center; */
    /* height: 100vh; */
}
.contenedorImgHover figure{
    position:relative;
    /* height: 350px; */
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
    /* box-shadow: 0px 20px 30px rgba(0,0,0,0.50); */
}
.contenedorImgHover figure span{
    width: 100%;
    height: 100%;
    transition: all 500ms ease-out;
}
.contenedorImgHover figure .capa{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(76,183,227,0.9); */
    transition: all 500ms ease-out;
    opacity: 0;
    visibility: hidden;
    /* text-align: center; */
}
.contenedorImgHover figure:hover > .capa {
    opacity: 1;
    visibility: visible;
    padding: 35px;
}
.contenedorImgHover figure:hover > .capa div{
    margin-top: 70px;
    /* margin-top: 30px; */
    /* margin-bottom: 15px; */
}
.contenedorImgHover figure:hover > .capa h2{
    /* margin-top: 70px; */
    margin-bottom: 15px;
}
.contenedorImgHover figure:hover > span{
    transform: scale(1.1);
}
.contenedorImgHover figure .capa h2{
    color: #fff;
    font-weight: 200;
    font-size: 20px;
    margin-bottom: 20px;
    transition: all 500ms ease-out;
    /* margin-top: 30px; */
}
.contenedorImgHover figure .capa p{
    color: #fff;
    font-size: 15px;
    line-height: 1.5;
    width: 100%;
    max-width: 220px;
    /* margin: auto; */
}

.nombreCategoria{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: white;
}

.nombreCategoriaSeleccionado{
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
}

#iconoImagenHover{
    /* width: 131.86px;
    height: 131.86px; */
}

#iconoImagenHoverSeleccionado{
    /* width: 84.06px;
    height: 84.06px; */
    animation: rotacionPromosCat 5s infinite linear;
}

@keyframes rotacionPromosCat {
    from {
        transform: rotateY(0deg);
    }
    to {
        transform: rotateY(360deg);
    }
}

#contenedorIcono{
    height: 135px;
    width: 135px;
    margin-bottom: 20px;
}

#contenedorIconoSeleccionado{
    width: 92.06px;
    height: 92.06px;
    margin-bottom: 5px;
    margin-top: -30px;
}

#nombreCategoriaHover{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
}

#textoPromocionHover{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
}

#saberMasHover{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
}

.contenedorNombreCategoria{
    width: 181px;
    height: 40px;
    border-radius: 21px;
    padding-top: 10px;
    margin-left: 110px;
}

.iconoFlecha{
    /* margin-top: 15px; */
    /* position: absolute; */
    margin-left: 20px;

}


.Contenedor-Img-Figura-Promociones{
    transition: all 500ms ease-out;
    transform: scale(1.0) !important;
    z-index: 1;
}

.Contenedor-Img-Figura-Promociones-Mouse{
    transition: all 500ms ease-out;
    transform: scale(1.1) !important;
}